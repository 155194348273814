import React from "react";

const processSteps = [
  {
    id: 1,
    title: "Book a Free Consultation",
    description:
      "It all starts with a free, no-obligation consultation where we learn about your business and discuss your website needs.",
    icon: "📅", // Placeholder for an icon
  },
  {
    id: 2,
    title: "Strategy Meeting",
    description:
      "After the initial call, we’ll schedule a more in-depth strategy session to go over your goals and how we can bring them to life.",
    icon: "💡",
  },
  {
    id: 3,
    title: "Website Development",
    description:
      "Our expert developers will create a fully responsive, SEO-optimized website tailored to your business needs, with a fast turnaround time of 2-5 days.",
    icon: "💻",
  },
  {
    id: 4,
    title: "Ongoing Support & Maintenance",
    description:
      "Once your website is live, we provide 24/7 support to help with any issues or updates you may need, keeping your website fresh and functional.",
    icon: "🔧",
  },
];

// Individual Process Step Card
const ProcessCard = ({ icon, title, description }) => (
  <div className="group bg-zinc-900 p-6 rounded-lg shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105">
    {/* Icon */}
    <div className="text-4xl text-blue-400 mb-4 group-hover:text-blue-300 transition-colors duration-300">
      {icon}
    </div>

    {/* Title */}
    <h3 className="text-lg font-bold mb-2 text-white">{title}</h3>

    {/* Description */}
    <p className="text-gray-400">{description}</p>
  </div>
);

// Our Process Component
const Process = () => {
  return (
    <div className="w-full px-6 py-12 bg-black text-center">
      <br />
      {/* Heading */}
      <h2 className="text-3xl font-bold text-white mb-6">Our Process</h2>
      <p className="text-gray-400 mb-12">
        At GoWeWeb, we make creating your business website simple and
        stress-free. Here’s how we work with you:
      </p>

      {/* Process Steps Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {processSteps.map((step) => (
          <ProcessCard
            key={step.id}
            icon={step.icon}
            title={step.title}
            description={step.description}
          />
        ))}
      </div>

      {/* Call to Action */}

      <a
        href="https://calendly.com/goweweb/speak-to-william?hide_gdpr_banner=1"
        target="_blank"
        className="mt-8"
      >
        <div className="mt-12">
          <button className="bg-blue-500 text-white py-3 px-6 rounded-lg hover:bg-blue-400 transition-colors duration-300">
            Schedule Your Free Call
          </button>
        </div>
      </a>
    </div>
  );
};

export default Process;
