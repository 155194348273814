import React from 'react';

const Logo = () => {
  return (
    <h1 className="text-4xl font-bold flex items-center">
      <span className="text-blue-200">G</span>
      <span className="text-blue-300">o</span>
      <span className="text-blue-400">W</span>
      <span className="text-blue-500">e</span>
      <span className="text-blue-600">W</span>
      <span className="text-blue-700">e</span>
      <span className="text-blue-800">b</span>
    </h1>
  );
};

export default Logo;