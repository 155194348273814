import React, { useState, useEffect } from 'react';

const CursorFollower = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isPointer, setIsPointer] = useState(false);
  const [smoothPosition, setSmoothPosition] = useState({ x: 0, y: 0 });

  // Set cursor position on mouse move
  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    const handlePointerDetection = (e) => {
      const target = e.target;
      setIsPointer(
        window.getComputedStyle(target).cursor === 'pointer' ||
        target.tagName.toLowerCase() === 'button' ||
        target.tagName.toLowerCase() === 'a'
      );
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseover', handlePointerDetection);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseover', handlePointerDetection);
    };
  }, []);

  // Smooth transition for the cursor follower
  useEffect(() => {
    const smoothMovement = () => {
      setSmoothPosition((prevPos) => ({
        x: prevPos.x + (position.x - prevPos.x) * 0.1,
        y: prevPos.y + (position.y - prevPos.y) * 0.1,
      }));
    };

    const animationFrame = requestAnimationFrame(smoothMovement);
    return () => cancelAnimationFrame(animationFrame);
  }, [position]);

  return (
    <div className="fixed top-0 left-0 w-full h-full pointer-events-none z-50 hidden md:block">
      {/* Outer glow */}
      <div
        className={`absolute w-8 h-8 rounded-full bg-blue-400/30 blur-xl transition-transform duration-100 ease-out`}
        style={{
          transform: `translate(${smoothPosition.x - 16}px, ${smoothPosition.y - 16}px)`,
        }}
      />
      
      {/* Inner dot */}
      <div
        className={`absolute w-4 h-4 rounded-full bg-gradient-to-r from-blue-200 to-blue-500 mix-blend-exclusion transition-all duration-100 ease-out ${
          isPointer ? 'scale-150' : 'scale-100'
        }`}
        style={{
          transform: `translate(${smoothPosition.x - 8}px, ${smoothPosition.y - 8}px)`,
        }}
      />
    </div>
  );
};

export default function App() {
  return (
    <CursorFollower />
  );
}