import React from "react";
import { motion } from "framer-motion";
import { Check } from "lucide-react";

const PricingCard = ({ title, price, features, isPopular }) => (
  <motion.div
    className={`relative bg-black border ${
      isPopular ? "border-blue-500" : "border-gray-800"
    } rounded-xl p-6 flex flex-col h-full`}
    whileHover={{ y: -10 }}
    transition={{ duration: 0.3 }}
  >
    {isPopular && (
      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
        <span className="bg-blue-500 text-white text-sm py-1 px-3 rounded-full">
          Most popular
        </span>
      </div>
    )}
    <div className="mb-8">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <div className="flex items-baseline">
        <span className="text-4xl font-bold">${price}</span>
        <span className="text-gray-400 ml-2">/month</span>
      </div>
    </div>
    <ul className="space-y-4 flex-grow">
      {features.map((feature, index) => (
        <motion.li
          key={index}
          className="flex items-center"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: index * 0.1 }}
        >
          <Check className="text-blue-500 mr-2" size={18} />
          <span>{feature}</span>
        </motion.li>
      ))}
    </ul>
    <a href="https://calendly.com/goweweb/speak-to-william?hide_gdpr_banner=1" target="_blank"><motion.button
      className={`mt-8 w-full py-3 rounded-lg transition-colors ${
        isPopular
          ? "bg-blue-500 hover:bg-blue-600 text-white"
          : "bg-gray-800 hover:bg-gray-700"
      }`}
      whileTap={{ scale: 0.95 }}
    >
      Get started
    </motion.button></a>
  </motion.div>
);

const Pricing = () => {
  const plans = [
    {
      title: "Small Business",
      price: 99 ,
      features: ["Originally $199"],
    },
    {
      title: "Medium-Sized Businesses",
      price: 199,
      features: ["Originally $299"],
      isPopular: true,
    },
    {
      title: "Large Companies",
      price: 299,
      features: ["Originally $399"],
    },
  ];

  return (
    <div className="min-h-screen bg-black text-white p-8">
      <br />
      <br />
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-12">
          <motion.h2
            className="text-4xl md:text-5xl font-bold mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Simple, transparent pricing
          </motion.h2>
          <motion.p
            className="text-gray-400 text-lg"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            We believe in making high-quality website development affordable for
            all businesses, without overwhelming you with upfront costs.
          </motion.p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <motion.div
              key={plan.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <PricingCard {...plan} />
            </motion.div>
          ))}
        </div>

        <a
          href="https://calendly.com/goweweb/speak-to-william?hide_gdpr_banner=1/"
          target="_blank"
        >
          <motion.div
            className="mt-12 text-center text-gray-400"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
          >
            Need something different?{" "}
            <button className="text-blue-500 hover:underline">
              Contact us
            </button>
          </motion.div>
        </a>
      </div>
    </div>
  );
};

export default Pricing;
