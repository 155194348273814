import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Dot from './components/Dot';
import SnowAnimation from './components/SnowAnimation';
import BackToTop from './components/BackToTop';
import BubbleBackground from './components/BubbleBackground'

import AIHeroSection from './main/AIHeroSection';
import HeroSection from './main/HeroSection';
import WhatWeDoSection from './main/WhatWeDoSection'; //services
import ProcessSection from './main/ProcessSection'; //process
import TeamSlider from './main/TeamSlider'; //team
import Pricing from './main/Pricing'; //pricing
import FAQ from './main/FAQ'; //faq
import Contact from './main/Contact'
import ReviewsSection from './main/Reviews';

import { Home } from 'lucide-react';
import AppointmentBooking from './pages/AppointmentBooking'
const App = () => {
  return (
    <div>
      
      <section id="header"><Header /></section>
      
      <HeroSection />
      <section id="services"><WhatWeDoSection /></section>
      <section id="process"><ProcessSection /></section>
      <section id="pricing"><Pricing /></section>
      <ReviewsSection />
      <section id="faq"><FAQ /></section>

      <Footer />
      <BackToTop />
      <Dot />
    </div>
  )
}

export default App