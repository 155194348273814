import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import rev1 from '../assets/images/rev1.jpg'
import rev2 from '../assets/images/rev2.jpg'
import rev3 from '../assets/images/rev3.jpg'
import rev4 from '../assets/images/rev4.jpg'
import rev5 from '../assets/images/rev5.jpg'
import rev6 from '../assets/images/rev6.jpg'
import rev7 from '../assets/images/rev7.jpg'
import rev8 from '../assets/images/rev8.jpg'
import rev9 from '../assets/images/rev9.jpg'
import rev10 from '../assets/images/rev10.jpg'

const reviews = [
  {
    text: "GoWeWeb exceeded my expectations with their professional and efficient service. The website they created is not only visually stunning but also user-friendly and easy to navigate.",
    name: "Emily K.",
    position: "Freelance Writer",
    image: rev1  // Placeholder for freelance writer image
  },
  {
    text: "Our website has been a huge success thanks to GoWeWeb. Their team is knowledgeable and professional, and they always go the extra mile to ensure our satisfaction.",
    name: "Ethan L",
    position: "Restaurant Owner",
    image: rev2  // Placeholder for restaurant owner image
  },
  {
    text: "GoWeWeb was instrumental in helping us launch our startup. Their team provided valuable guidance and support throughout the entire process.",
    name: "Leo T",
    position: "Startup Founder",
    image: rev3  // Placeholder for startup founder image
  },
  {
    text: "The GoWeWeb team was a pleasure to work with. They were always responsive, helpful, and went above and beyond to ensure my satisfaction.",
    name: "Olivia R",
    position: "Nonprofit Organization",
    image: rev4  // Placeholder for nonprofit org image
  },
  {
    text: "GoWeWeb helped me create a website that effectively promotes my online courses. Their team is very responsive and always willing to help.",
    name: "Noah S",
    position: "Online Course Creator",
    image: rev5  // Placeholder for course creator image
  },
  
  {
    text: "GoWeWeb delivered exactly what I needed for my business website in a matter of days. They were attentive to every detail, and the website runs smoothly on all devices. I couldn’t be happier with the result and the ongoing support they provide.",
    name: "Clifton L",
    position: "Small Business Owner",
    image: rev6  // Placeholder for course creator image
  },
  {
    text: "The team at GoWeWeb built our company’s website in just a few days, and the results exceeded our expectations. They listened carefully to our needs and were always available to answer any questions. The website is both visually stunning and easy to navigate.",
    name: "David B",
    position: "Marketing Director",
    image: rev7  // Placeholder for course creator image
  },
  {
    text: "GoWeWeb truly understands what businesses need in an online presence. They were very communicative throughout the process, and their design ideas were spot on. The website they created for us has significantly boosted our online visibility.",
    name: "Charles L",
    position: "Fitness Coach",
    image: rev8  // Placeholder for course creator image
  },
  {
    text: "Working with GoWeWeb was a fantastic experience. Their team made the entire website development process stress-free, and they were incredibly patient with all my requests. Their support even after the website launch has been top-notch.",
    name: "Dave M",
    position: "E-Commerce Business Owner",
    image: rev9  // Placeholder for course creator image
  },
  {
    text: "We were looking to modernize our outdated website, and GoWeWeb completely revamped it into something sleek and professional. Their team was creative, efficient, and always responsive to our feedback. We’ve seen a significant increase in customer engagement since launching the new site.",
    name: "Mark G",
    position: "Nonprofit Director",
    image: rev10  // Placeholder for course creator image
  },
  {
    text: "GoWeWeb helped me create a website that effectively promotes my online courses. Their team is very responsive and always willing to help.",
    name: "Will S",
    position: "Online Course Creator",
    image: rev1  // Placeholder for course creator image
  },
  // ... other reviews remain the same, but with image URLs instead of emojis
];

const ReviewCard = ({ review }) => (
  <motion.div
    className="bg-neutral-900 rounded-xl p-4 sm:p-6 min-w-[250px] sm:min-w-[300px] md:w-[350px] lg:w-[400px] mx-2 sm:mx-4 backdrop-blur-sm bg-opacity-50 border border-neutral-800 hover:border-blue-500 transition-all duration-300"
    whileHover={{ 
      scale: 1.02,
      boxShadow: "0 0 20px rgba(59, 130, 246, 0.3)"
    }}
  >
    <div className="mb-3 sm:mb-4">
      <img 
        src={review.image} 
        alt={`${review.name} avatar`} 
        className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
      />
    </div>
    <p className="text-white text-sm sm:text-base mb-3 sm:mb-4 line-clamp-4">{`"${review.text}"`}</p>
    <div className="mt-auto">
      <p className="text-white font-semibold text-sm sm:text-base">{review.name}</p>
      <p className="text-blue-400 text-xs sm:text-sm">{review.position}</p>
    </div>
  </motion.div>
);

const InfiniteSlider = ({ items, direction = 'left', speed = 20 }) => {
  const [duplicatedItems, setDuplicatedItems] = useState([]);
  const [sliderWidth, setSliderWidth] = useState(0);

  useEffect(() => {
    setDuplicatedItems([...items, ...items]);
    
    const updateWidth = () => {
      const cardWidth = window.innerWidth < 640 ? 250 : 
                        window.innerWidth < 768 ? 300 : 
                        window.innerWidth < 1024 ? 350 : 400;
      const totalWidth = cardWidth * items.length;
      setSliderWidth(totalWidth);
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [items]);

  return (
    <div className="overflow-hidden relative w-full h-[250px] sm:h-[280px] md:h-[300px] my-2 sm:my-4">
      <motion.div
        className="flex"
        animate={{
          x: direction === 'left' ? [-sliderWidth, 0] : [0, -sliderWidth]
        }}
        transition={{
          duration: speed,
          ease: "linear",
          repeat: Infinity,
        }}
      >
        {duplicatedItems.map((review, index) => (
          <ReviewCard key={index} review={review} />
        ))}
      </motion.div>
    </div>
  );
};

const ReviewsSection = () => {
  const halfLength = Math.ceil(reviews.length / 2);
  const firstRow = reviews.slice(0, halfLength);
  const secondRow = reviews.slice(halfLength);

  return (
    <div className="bg-black text-white py-8 sm:py-12 lg:py-16 px-4 sm:px-6 lg:px-8 overflow-hidden">
      <motion.div 
        className="text-center mb-8 sm:mb-12 lg:mb-16"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-3xl sm:text-5xl lg:text-7xl font-bold mb-2 sm:mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
          Client Reviews
        </h2>
        <p className="text-neutral-400 text-sm sm:text-base lg:text-lg max-w-xl sm:max-w-2xl mx-auto px-4">
          Don't just take our word for it. See what our clients have to say about their experience working with us.
        </p>
      </motion.div>

      <div className="relative">
        <div className="absolute left-0 w-16 sm:w-32 h-full bg-gradient-to-r from-black to-transparent z-10"></div>
        <div className="absolute right-0 w-16 sm:w-32 h-full bg-gradient-to-l from-black to-transparent z-10"></div>
        
        <InfiniteSlider items={firstRow} direction="left" speed={40} />
        <InfiniteSlider items={secondRow} direction="right" speed={35} />
      </div>
    </div>
  );
};

export default ReviewsSection;