import React from 'react';
import { Globe, Search, Bot, BarChart, DollarSign, Target } from 'lucide-react';

const ServiceCard = ({ icon: Icon, title, description, features }) => (
  <div className="bg-neutral-900 rounded-xl p-6 backdrop-blur-sm bg-opacity-50 border border-neutral-800 transition-all duration-300 hover:scale-[1.02] hover:border-blue-500 hover:shadow-[0_0_20px_rgba(59,130,246,0.2)]">
    <div className="bg-gradient-to-br from-blue-500 to-purple-600 w-12 h-12 rounded-lg flex items-center justify-center mb-4">
      <Icon className="text-white w-6 h-6" />
    </div>
    <h3 className="text-xl font-bold mb-3 text-white">{title}</h3>
    <p className="text-neutral-400 mb-4">{description}</p>
    {features && (
      <div className="space-y-2">
        <h4 className="text-lg font-semibold text-white mb-2">Features:</h4>
        {features.map((feature, index) => (
          <div 
            key={index}
            className="flex items-start space-x-2"
          >
            <div className="w-1.5 h-1.5 rounded-full bg-blue-500 mt-2" />
            <p className="text-neutral-400">{feature}</p>
          </div>
        ))}
      </div>
    )}
  </div>
);

const BenefitCard = ({ icon: Icon, title, description }) => (
  <div className="flex space-x-4 transition-transform duration-300 hover:scale-[1.02]">
    <div className="bg-gradient-to-br from-blue-500 to-purple-600 w-12 h-12 rounded-lg flex items-center justify-center flex-shrink-0">
      <Icon className="text-white w-6 h-6" />
    </div>
    <div>
      <h3 className="text-lg font-bold mb-2 text-white">{title}</h3>
      <p className="text-neutral-400">{description}</p>
    </div>
  </div>
);

const WhatWeDoSection = () => {
  const services = [
    {
      icon: Globe,
      title: "Website Development",
      description: "We specialize in creating professional, fully responsive websites that work seamlessly across all devices. Your website will be custom-built to meet your business needs, complete with SEO integration to help your business rank higher on search engines.",
      features: [
        "Custom Design: Every website is tailored to reflect your brand.",
        "Mobile-Responsive: Your site will look great on any device.",
        "SEO Optimized: We build websites with search engine visibility in mind.",
        "Fast Delivery: We'll have your site ready in 2-5 days.",
        "24/7 Support: Ongoing maintenance and support to keep your website running smoothly."
      ]
    },
    {
      icon: Search,
      title: "SEO Services",
      description: "Get noticed with our SEO services. While every website we build is SEO-optimized from the start, we also offer Local SEO to ensure your business stands out to customers in your area. Our team will help improve your visibility, so you can attract more local traffic and convert leads into customers."
    },
    {
      icon: Bot,
      title: "AI Chatbots",
      description: "Enhance your website with our AI-powered chatbots, designed to handle customer inquiries, automate appointment bookings, and improve your overall customer experience. With an AI chatbot, your business is always ready to answer questions and engage customers 24/7."
    }
  ];

  const benefits = [
    {
      icon: BarChart,
      title: "A Website That Works for You",
      description: "We don't just build websites; we create online platforms that grow your business. With built-in SEO, responsive design, and engaging features like AI chatbots, we help you reach more customers."
    },
    {
      icon: DollarSign,
      title: "No Hidden Fees, Just Value",
      description: "Unlike many agencies, we offer clear, monthly pricing with no surprises. You pay nothing upfront. Our goal is to deliver high-quality services that you can afford, with full transparency."
    },
    {
      icon: Target,
      title: "SEO Built In",
      description: "Every website we create is optimized for search engines to ensure your business gets found. Want to go deeper with SEO? We also offer advanced Local SEO to drive more local customers to your site."
    }
  ];

  return (
    <div className="bg-black text-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="absolute inset-0 bg-black">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600/30 via-transparent to-transparent" />
        <div className="absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-black to-transparent" />
      </div>
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            Our Services
          </h2>
          <p className="text-neutral-400 text-lg max-w-2xl mx-auto">
            We provide comprehensive solutions to help your business thrive online.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-20">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        <div className="text-center mb-12">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4">
            How We Help Your Business Grow
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <BenefitCard key={index} {...benefit} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDoSection;