import React from "react";

const Copyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="m-12 p-8 border-t border-gray-800 flex flex-col items-center justify-center ">
      <p className="text-sm text-center text-gray-400">
        © {currentYear}, GoWeWeb - All rights reserved.
      </p>
    </div>
  );
};

export default Copyright;
