import React from "react";
import Logo from "./Logo";
import Copyright from "./Copyright";
import { motion } from "framer-motion";


const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="text-surface bg-black ark:text-white py-10">
      {/* Footer Content */}
      <div className="container mx-auto px-6 md:px-12 lg:px-20">
        {/* Grid Layout for Larger Screens */}
        <div className="flex flex-col items-center md:flex-row md:justify-between space-y-6 md:space-y-0">
          {/* Logo */}
          <div className="mb-4 md:mb-0">
            <Logo />
          </div>

          {/* Social Media Icons */}
          <div className="flex space-x-4">
            {/* Facebook Icon */}
            <a
              href="#!"
              className="rounded-full bg-blue-500 p-2 text-white hover:bg-blue-600 transition duration-300"
              aria-label="Facebook"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 320 512" className="w-5 h-5">
                <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5 16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/>
              </svg>
            </a>
            {/* Instagram Icon */}
            <a
              href="#!"
              className="rounded-full bg-pink-500 p-2 text-white hover:bg-pink-600 transition duration-300"
              aria-label="Instagram"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512" className="w-5 h-5">
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8z"/>
              </svg>
            </a>
            {/* LinkedIn Icon */}
            <a
              href="#!"
              className="rounded-full bg-blue-700 p-2 text-white hover:bg-blue-800 transition duration-300"
              aria-label="LinkedIn"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512" className="w-5 h-5">
                <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 1 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"/>
              </svg>
            </a>
          </div>
        </div>
        
        {/* Divider */}

        {/* Copyright Section */}
        <Copyright />
      </div>
    </footer>
  );
};

export default Footer;
