import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Plus } from "lucide-react";

const FAQItem = ({ question, answer, index }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="border-b border-gray-800 relative group"
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="w-full py-6 flex justify-between items-center text-left transition-colors relative"
      >
        <motion.span
          className="text-lg pr-8"
          animate={{ color: isHovered ? "#fff" : "#A1A1A1" }}
        >
          {question}
        </motion.span>
        <motion.div
          animate={{
            rotate: isOpen ? 45 : 0,
            backgroundColor: isHovered
              ? "rgba(255, 255, 255, 0.1)"
              : "transparent",
          }}
          className="absolute right-0 w-10 h-10 flex items-center justify-center rounded-full transition-colors"
        >
          <Plus className="w-5 h-5" />
        </motion.div>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: "auto",
              opacity: 1,
              transition: {
                height: { duration: 0.4 },
                opacity: { duration: 0.25, delay: 0.15 },
              },
            }}
            exit={{
              height: 0,
              opacity: 0,
              transition: {
                height: { duration: 0.4 },
                opacity: { duration: 0.25 },
              },
            }}
            className="overflow-hidden"
          >
            <p className="pb-6 text-gray-400 text-base max-w-3xl">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const CustomCursor = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);

  React.useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <motion.div
      className="fixed top-0 left-0 w-4 h-4 bg-blue-500 rounded-full pointer-events-none z-50 mix-blend-difference hidden sm:block" // Using Tailwind's responsive utility
      animate={{
        x: mousePosition.x - 8,
        y: mousePosition.y - 8,
        scale: isHovered ? 1.5 : 1,
      }}
      transition={{ type: "spring", stiffness: 500, damping: 28 }}
    />
  );
};

const FAQ = () => {
  const faqItems = [
    {
      question: "What is the cost to get started?",
      answer:
        "There are no upfront costs. Our pricing starts at $199/month for small businesses, $249/month for medium-sized businesses, and $299/month for larger companies, with a 1-year contract.",
    },
    {
      question: "What does the monthly fee include?",
      answer:
        "The monthly fee covers everything—website development, 24/7 support, SEO optimization, and a responsive design. It's a comprehensive solution with no hidden fees.",
    },
    {
      question: "How long does it take to build the website?",
      answer:
        "We deliver websites within 2-5 days, depending on the complexity of the project.",
    },
    {
      question: "Is SEO included?",
      answer:
        "Yes! Every website we build is SEO-optimized to help improve your search rankings. We also offer Local SEO as an additional service to help your business attract more local customers.",
    },
    {
      question: "Do you offer customer support?",
      answer:
        "Yes, we offer 24/7 support for any website-related issues. Whether you need help with a small tweak or a larger update, we're here to assist.",
    },
    {
      question: "What happens after my website is live?",
      answer:
        "Once your website is live, we provide ongoing support to ensure everything runs smoothly. You'll also have access to our team for any changes, updates, or improvements needed.",
    },
  ];

  // Split the FAQ items into two columns
  const midPoint = Math.ceil(faqItems.length / 2);
  const leftColumnItems = faqItems.slice(0, midPoint);
  const rightColumnItems = faqItems.slice(midPoint);

  return (
    <div className="min-h-screen bg-black text-white p-4 sm:p-8">
      <CustomCursor />
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12 sm:mb-16">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
            className="relative inline-block mb-4"
          >
            <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
          </motion.div>
          <motion.h2
            className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Answers
          </motion.h2>
          <motion.p
            className="text-gray-400 text-base sm:text-lg"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            We've gone ahead and answered some of the questions you might have.
          </motion.p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="space-y-0">
            {leftColumnItems.map((item, index) => (
              <FAQItem
                key={index}
                question={item.question}
                answer={item.answer}
                index={index}
              />
            ))}
          </div>
          <div className="space-y-0">
            {rightColumnItems.map((item, index) => (
              <FAQItem
                key={index + midPoint}
                question={item.question}
                answer={item.answer}
                index={index + midPoint}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <motion.div
        className="flex flex-col sm:flex-row gap-4 justify-center items-center"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <a
          href="https://calendly.com/goweweb/speak-to-william?hide_gdpr_banner=1"
          target="_blank"
          className="mt-8 self-center"
        >
          <div className="mt-12">
            <button className="bg-blue-500 text-white py-3 px-6 rounded-lg hover:bg-blue-400 transition-colors duration-300">
              Schedule Your Free Call
            </button>
          </div>
        </a>
      </motion.div>
    </div>
  );
};

export default FAQ;
