import React, { useState, useEffect } from "react";
import { Menu } from "lucide-react";
import Logo from "./Logo";

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navLinks = [
    { href: "#services", text: "Services" },
    { href: "#process", text: "Process" },
/*     { href: "#team", text: "Team" },
 */    { href: "#pricing", text: "Pricing" },
    { href: "#faq", text: "FAQ" },
  ];

  return (
    <>
      <header
        className={`fixed w-full transition-all duration-300 z-50 ${
          isScrolled ? "py-4" : "py-4"
        }`}
      >
        <nav
          className={`mx-auto flex items-center justify-between transition-all duration-300 ${
            isScrolled
              ? "max-w-[90%] bg-black rounded-full px-6 py-2"
              : "container px-4"
          }`}
        >
          <div className="flex items-center space-x-2">
            <a href="#header">
              <span className="text-white text-2xl">
                <Logo />
              </span>
            </a>
            <span
              className={`text-white font-medium transition-all ${
                isScrolled ? "hidden" : "block"
              }`}
            >
              {/* Halo */}
            </span>
          </div>

          <div className="hidden md:flex items-center space-x-6">
            <div
              className={`flex space-x-6 ${
                isScrolled
                  ? ""
                  : "bg-black/80 backdrop-blur-sm rounded-full px-6 py-2"
              }`}
            >
              {navLinks.map((link) => (
                <a
                  key={link.href}
                  href={link.href}
                  className="text-gray-300 hover:text-white transition-colors text-sm"
                >
                  {link.text}
                </a>
              ))}
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <a
              href="https://calendly.com/goweweb/speak-to-william?hide_gdpr_banner=1"
              target="_blank"
              className="hidden md:block"
            >
              <button
                className={`text-sm transition-all ${
                  isScrolled
                    ? "text-gray-300 hover:text-white"
                    : "bg-black text-white px-4 py-2 rounded-md hover:bg-blue-200 hover:text-black"
                }`}
              >
                {isScrolled ? "Let's Talk" : "Let us Talk"}
              </button>
            </a>

            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden w-10 h-10 flex items-center justify-center"
            >
              <div className="relative w-6 h-6">
                <span
                  className={`absolute w-6 h-0.5 bg-white transform transition-all duration-300 ${
                    isMenuOpen ? "rotate-45 top-3" : "rotate-0 top-1"
                  }`}
                ></span>
                <span
                  className={`absolute w-6 h-0.5 bg-white top-3 transition-all duration-300 ${
                    isMenuOpen ? "opacity-0" : "opacity-100"
                  }`}
                ></span>
                <span
                  className={`absolute w-6 h-0.5 bg-white transform transition-all duration-300 ${
                    isMenuOpen ? "-rotate-45 top-3" : "rotate-0 top-5"
                  }`}
                ></span>
              </div>
            </button>
          </div>
        </nav>
      </header>

      {/* Mobile Menu Overlay */}
      <div
        className={`fixed inset-0 bg-black z-40 transition-transform duration-300 ease-in-out ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="container mx-auto px-4 py-24">
          <div className="flex flex-col space-y-6">
            {navLinks.map((link) => (
              <a
                key={link.href}
                href={link.href}
                className="text-gray-300 hover:text-white text-3xl font-semibold transition-colors"
                onClick={() => setIsMenuOpen(false)}
              >
                {link.text}
              </a>
            ))}
            <a
              href="https://calendly.com/goweweb/speak-to-william?hide_gdpr_banner=1"
              target="_blank"
              className="mt-8"
            >
              <button className="w-full bg-white text-black px-6 py-3 rounded-md hover:bg-gray-200 text-lg font-medium">
                Let's Talk
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
