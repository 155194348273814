import React from "react";

export default function HeroSection() {
  return (
    <section className="relative h-screen flex items-center justify-center overflow-hidden">
      {/* Background gradient */}
      <div className="absolute inset-0 bg-black">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-600/30 via-transparent to-transparent" />
        <div className="absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-black to-transparent" />
      </div>

      {/* Content container */}
      <div className="relative z-10 max-w-7xl mx-auto px-6 text-center">
        <h1 className="text-5xl md:text-7xl font-bold text-white mb-6 animate-fade-in">
          We Build It,
          <span className="bg-gradient-to-r from-blue-400 to-blue-600 text-transparent bg-clip-text">
            {" "}
            You Watch It Grow
          </span>
        </h1>

        <p className="text-gray-400 text-lg md:text-xl max-w-2xl mx-auto mb-8 animate-fade-in-delay">
          Build a responsive, SEO-optimized website with 24/7 support that’s
          designed to help your business grow. No upfront costs—just results.
        </p>

        <div className="flex flex-col sm:flex-row gap-4 justify-center items-center animate-fade-in-delay-long">
          <a
            href="https://calendly.com/goweweb/speak-to-william?hide_gdpr_banner=1"
            target="_blank"
            className="mt-8"
          >
            <button className="bg-white text-black px-6 py-3 rounded-full font-medium hover:bg-gray-100 transition-colors">
              Book a meeting
            </button>
          </a>

          <a href="#pricing" className="mt-8">
            <button className="text-white hover:text-gray-300 transition-colors">
              View pricing →
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}
